import React from "react";
import {
  DialogActions,
  Grid,
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import "./javaModal.css";
import java from "../../../images/java_color_1.png";

interface Props {
  open: any;
  modalClose: any;
}

export default function JavaModal({ open, modalClose }: Props) {
  const { t } = useTranslation();

  return (
    <div>
      <Dialog
        style={{ borderRadius: 2 }}
        open={open}
        onClose={modalClose}
        aria-labelledby="java_modal-title"
        aria-describedby="java_modal-description"
      >
        <Grid container>
          <Grid item xs={12} sm={12} className="nodeJs_modal_title">
            <Grid item xs={1} sm={1}>
              <img src={java} alt="" className="nodeJs_modal_logo_img" />
            </Grid>
            <Grid item sm={12} xs={12}>
              <DialogTitle id="java_modal-title" className="java_modal_title">
                {t("javaModal.javaTitle")}
              </DialogTitle>
            </Grid>
          </Grid>
          <DialogContent>
            <Grid item sm={12}>
              <DialogContentText
                id="java_modal-description"
                className="java_modal_content"
              >
                {t("javaModal.javaText1")}
              </DialogContentText>
            </Grid>
            <Grid item sm={12}>
              <Typography variant="h6" className="nodeJs_modal_typography">
                {/* {t("homePage.reactUsedForTitle")} */}
              </Typography>
              <DialogContentText
                id="java_modal-description"
                className="java_modal_content"
              >
                {t("javaModal.javaText2")}
              </DialogContentText>
            </Grid>
            <Grid item sm={12}>
              <Typography variant="h6" className="nodeJs_modal_typography">
                {/* {t("homePage.reactProgrammingTitle")} */}
              </Typography>
              <DialogContentText
                id="java_modal-description"
                className="java_modal_content"
              >
                {t("javaModal.javaText3")}
              </DialogContentText>
              <DialogActions className="nodeJs_modal_dialogActions">
                <Button
                  variant="outlined"
                  className="nodeJs_modal_button"
                  onClick={modalClose}
                >
                  {t("buttons.close")}
                </Button>
              </DialogActions>
            </Grid>
          </DialogContent>
        </Grid>
      </Dialog>
    </div>
  );
}
