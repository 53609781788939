import React from "react";
import {
  Grid,
  Typography,
  makeStyles,
  createStyles,
  Theme,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    text: {
      color: "#849AA3",
    },
    title: {
      color: "#849AA3",
      fontWeight: 900,
    },
  })
);

export default function ContactUsSection() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid>
      <Grid item xs={12} sm={12}>
        <Typography variant="h5" className={classes.title} align="center">
          {t("homePage.contact")}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography align="center" className={classes.text}>
          <b>{t("homePage.contactAddress")}</b>
          {t("homePage.contactAddressContent")}
        </Typography>
        <Typography align="center" className={classes.text}>
          <b>{t("homePage.contactPhone")}</b>
          {t("homePage.contactPhoneContent")}
        </Typography>
        <Typography align="center" className={classes.text}>
          <b>{t("homePage.contactEmail")}</b>
          {t("homePage.contanctEmailContent")}
        </Typography>
      </Grid>
    </Grid>
  );
}
