import React from "react";
import {
  Grid,
  Typography,
  makeStyles,
  createStyles,
  Theme,
} from "@material-ui/core";
import img1 from "../../../images/rightBlock.png";
import clientFirst from "../../../images/clientFirst.jpg";
import strongExperts from "../../../images/strongExperts.jpg";
import delivery from "../../../images/delivery.jpg";
import CardHomePage from "../../../components/cardHomePage/cardHomePage";
import { useTranslation } from "react-i18next";
import { useMediaQuery, useTheme } from "@mui/material";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      //   padding: "20px",
    },
    text: {
      marginTop: "20px",
    },
    whyUs_div: {
      alignContent: "center",
      justifyContent: "center",
      fontWeight: "bold",
    },
    img: {
      width: "75%",
      paddingRight: "20px",
      textAlign: "center",
      height: "auto",
      [theme.breakpoints.down("xs")]: {
        paddingRight: 0,
      },
    },
    imgContainer: {
      display: "flex",
      justifyContent: "right",
    },
  })
);

export default function WhyUsSection() {
  const theme = useTheme();
  const classes = useStyles();
  const { t } = useTranslation();

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Grid className={classes.root} container spacing={0}>
      <Grid container item sm={6} xs={12} alignContent="flex-start">
        <Typography variant="h5" className={classes.whyUs_div}>
          {t("homePage.whyUs")}
        </Typography>
        <Grid>
          <Typography>{t("homePage.whyUsContent1")}</Typography>
          <Typography className={classes.text} style={{ fontWeight: "0.8rem" }}>
            {t("homePage.whyUsContent2")}
          </Typography>
          <Typography className={classes.text}>
            {t("homePage.whyUsContent3")}
          </Typography>
        </Grid>
      </Grid>
      <Grid item sm={6} xs={12} style={{ marginBottom: "10px" }}>
        <div
          className={classes.imgContainer}
          style={{ justifyContent: isMobile ? "center" : "right" }}
        >
          <img src={img1} alt="content1" className={classes.img} />
        </div>
      </Grid>
      <Grid item md={12} xs={12}>
        <CardHomePage />
      </Grid>
    </Grid>
  );
}
