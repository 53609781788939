/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from "react";
import { Container, Grid, useMediaQuery, useTheme } from "@material-ui/core";
import Logo1 from "../../images/certificate.png";
import Logo2 from "../../images/microsoftPartner.png";
import Logo3 from "../../images/AccreditedAgencyBlue.png";
import "./logoBar.css";

export default function LogoBar() {
  const [currentCompanyIndex, setCurrentCompanyIndex] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const companies = [
    "https://assets.goodfirms.co/badges/normal-badge/top-website-development-companies.svg",
    "https://assets.goodfirms.co/badges/normal-badge/top-software-development-companies.svg",
    "https://assets.goodfirms.co/badges/normal-badge/devops-companies.svg",
    "https://assets.goodfirms.co/badges/normal-badge/top-web-design-companies.svg",
    "https://assets.goodfirms.co/badges/normal-badge/web-hosting-companies.svg",
  ];

  useEffect(() => {
    const switchToNextCompany = () => {
      setCurrentCompanyIndex((prevIndex) => (prevIndex + 1) % companies.length);
    };

    const interval = setInterval(switchToNextCompany, 2000);

    return () => clearInterval(interval);
  }, [companies.length]);

  return (
    <div className="logoBar_center">
      {isMobile ? (
        <Grid container>
          <Grid item md={12} xs={12}>
            {companies.map((company, index) => (
              <a
                key={index}
                target="_blank"
                href="https://www.goodfirms.co/company/midenas"
              >
                <img
                  src={company}
                  title="Company"
                  alt="Company"
                  style={{
                    display: index === currentCompanyIndex ? "block" : "none",
                  }}
                />
              </a>
            ))}
          </Grid>
          <Grid
            item
            md={12}
            xs={12}
            justifyContent="center"
            style={{ display: "flex", marginTop: "20px" }}
          >
            <a
              href="https://www.designrush.com/agency/profile/midenas"
              target="_blank"
              style={{ marginLeft: "50px", marginRight: "50px" }}
            >
              <img src={Logo3} alt="" className="" />
            </a>
          </Grid>
          <Grid
            item
            md={12}
            xs={12}
            justifyContent="center"
            style={{ display: "flex", marginTop: "20px" }}
          >
            <img
              src={Logo1}
              alt=""
              className=""
              style={{
                marginLeft: "50px",
                marginRight: "50px",
                width: "250px",
              }}
            />
          </Grid>
          <Grid
            item
            md={12}
            xs={12}
            justifyContent="center"
            style={{ display: "flex", marginTop: "20px" }}
          >
            <img src={Logo2} alt="" className="" style={{ width: "250px" }} />
          </Grid>
        </Grid>
      ) : (
        <Grid className="logoBar_img logoBar_img_container">
          {companies.map((company, index) => (
            <a
              key={index}
              target="_blank"
              href="https://www.goodfirms.co/company/midenas"
            >
              <img
                src={company}
                title="Company"
                alt="Company"
                style={{
                  display: index === currentCompanyIndex ? "block" : "none",
                }}
              />
            </a>
          ))}
          <a
            href="https://www.designrush.com/agency/profile/midenas"
            target="_blank"
            style={{ marginLeft: "50px", marginRight: "50px" }}
          >
            <img src={Logo3} alt="" className="" />
          </a>
          <img
            src={Logo1}
            alt=""
            className=""
            style={{ marginLeft: "50px", marginRight: "50px", width: "250px" }}
          />
          <img src={Logo2} alt="" className="" style={{ width: "250px" }} />
        </Grid>
      )}
    </div>
  );
}
