import React from "react";
import AboutUsPage from "../features/aboutUs/aboutUsPage/aboutUsPage";
import ContactUsPage from "../features/pages/contactUsPage/contactUsPage";
import AddClientPage from "../features/dashboard/addClientPage/addClientPage";
import AddDocumentPage from "../features/dashboard/addDocumentPage/addDocumentPage";
import Dashboard from "../features/dashboard/dashboard";
import PasswordChangePage from "../features/dashboard/passwordChangePage/passwordChangePage";
import ProfilePage from "../features/dashboard/profile/profilePage";
import TemplatePage from "../features/dashboard/template/templatePage";
import HomePage from "../features/pages/homePage/homePage";
import LearningPage from "../features/learningPage/learningPage";
import LoginPage from "../features/loginPage/loginPage";
import MidenasSmartInvoicingPage from "../features/midenasSmartInvoicingPage/midenasSmartInvoicingPage";
import { BrowserRouter, Route } from "react-router-dom";
import {
  homePage as homePageRoute,
  smartInvoicing as smartInvoicingRoute,
  loginPage as loginPageRoute,
  learningPage as learningPageRoute,
  aboutUsPage as aboutUsPageRoute,
  contactPage as contactPageRoute,
  dashboard as dashboardRoute,
  profile as profileRoute,
  addInvoice as addInvoiceRoute,
  passwordChange as passwordChangeRoute,
  addClient as addClientRoute,
  template as templateRoute,
} from "./routes";
import LearningMainPage from "../features/learningPage/learningMainPage";

export default function Routing() {
  return (
    <BrowserRouter>
      <Route exact path={homePageRoute()} component={HomePage}></Route>
      <Route
        exact
        path={smartInvoicingRoute()}
        component={MidenasSmartInvoicingPage}
      ></Route>
      <Route exact path={loginPageRoute()} component={LoginPage}></Route>
      <Route
        exact
        path={learningPageRoute()}
        component={LearningMainPage}
      ></Route>
      <Route exact path={aboutUsPageRoute()} component={AboutUsPage}></Route>
      <Route exact path={contactPageRoute()} component={ContactUsPage}></Route>
      <Route exact path={dashboardRoute()} component={Dashboard}></Route>
      <Route exact path={profileRoute()} component={ProfilePage}></Route>
      <Route exact path={addInvoiceRoute()} component={AddDocumentPage}></Route>
      <Route
        exact
        path={passwordChangeRoute()}
        component={PasswordChangePage}
      ></Route>
      <Route exact path={addClientRoute()} component={AddClientPage}></Route>
      <Route exact path={templateRoute()} component={TemplatePage}></Route>
    </BrowserRouter>
  );
}
