import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";
import clientFirstImage from "../../images/client_first2.png";
import StrongExpertiseImage from "../../images/strong_expertise1.png";
import OnDeliveryTimeImage from "../../images/on_delivery_time1.png";
import { useTranslation } from "react-i18next";
import { useClientPrioritiesSectionStyles } from "./clientPrioritiesStyles";
import clientFirst from "../../images/clientFirst.jpg";
import strongExperts from "../../images/strongExperts.jpg";
import delivery from "../../images/delivery.jpg";

export default function ClientPrioritiesSection() {
  const { t } = useTranslation();

  const { classes } = useClientPrioritiesSectionStyles();
  const cardData = [
    {
      title: (
        <Card>
          <CardHeader
            title={t("cards.cardClientTitle")}
            style={{ color: "black" }}
          />

          <CardMedia
            className={classes.media}
            image={clientFirst}
            title="Paella dish"
          />
          <CardContent>
            <Typography
              color="textSecondary"
              component="p"
              style={{ fontSize: "0.8rem" }}
            >
              {t("cards.cardClientShort")}
            </Typography>
          </CardContent>
        </Card>
      ),
      description: t("cards.cardClientDesc"),
      backgroundImg: clientFirstImage,
    },
    {
      title: (
        <Card>
          <CardHeader
            title={t("cards.cardStrongExpertiseTitle")}
            style={{ color: "black" }}
          />

          <CardMedia
            className={classes.media}
            image={strongExperts}
            title="Paella dish"
          />
          <CardContent>
            <Typography
              color="textSecondary"
              component="p"
              style={{ fontSize: "0.8rem" }}
            >
              {t("cards.cardStrongExpertiseShort")}
            </Typography>
          </CardContent>
        </Card>
      ),
      description: t("cards.cardStrongExpertiseDesc"),
      backgroundImg: StrongExpertiseImage,
    },
    {
      title: (
        <Card>
          <CardHeader
            title={t("cards.cardDeliveryOnTimeTitle")}
            style={{ color: "black" }}
          />
          <CardMedia
            className={classes.media}
            image={delivery}
            title="Paella dish"
          />
          <CardContent>
            <Typography
              color="textSecondary"
              component="p"
              style={{ fontSize: "0.8rem" }}
            >
              {t("cards.cardDeliveryOnTimeShort")}
            </Typography>
          </CardContent>
        </Card>
      ),
      description: t("cards.cardDeliveryOnTimeDesc"),
      backgroundImg: OnDeliveryTimeImage,
    },
  ];

  return (
    <>
      <Grid
        container
        justifyContent="center"
        sx={{ display: "flex", gap: "80px" }}
      >
        {cardData.map((data) => (
          <div className={classes.cardContainer}>
            <div
              className={classes.card}
              style={{
                backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url(${data.backgroundImg})`,
              }}
            >
              <div className="frontContent">
                <p>{data.title}</p>
              </div>
              <div className="content">
                <Typography style={{ fontSize: "0.8rem" }}>
                  {data.description}
                </Typography>
              </div>
            </div>
          </div>
        ))}
      </Grid>
    </>
  );
}
