import { makeStyles } from "tss-react/mui";
import { Theme } from "@mui/material/styles";

export const useTechnologyExperienceStyles = makeStyles()((theme: Theme) => ({
  title: {
    color: "#fff",
  },
  box: {
    background: "#6A7C82",
    padding: "40px",
    borderRadius: "15px",
  },
  icon: {
    width: "100px",
  },
  iconsSubtitle: {
    color: "#fff",
    fontSize: "18px",
    marginTop: "auto",
  },
  iconWrapper: {
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  gridContainer: {
    height: "100%",
  },
}));
