import React from "react";
import {
  DialogActions,
  Grid,
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import cSharp from "../../../images/csharpblue.png";
import { useTranslation } from "react-i18next";
import "./cSharpModal.css";

interface Props {
  open: any;
  modalClose: any;
}

export default function CSharpModal({ open, modalClose }: Props) {
  const { t } = useTranslation();

  return (
    <div>
      <Dialog
        style={{ borderRadius: 2 }}
        open={open}
        onClose={modalClose}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
      >
        <Grid container>
          <Grid item xs={12} sm={12} className="cSharpModal_modal_title">
            <Grid item xs={1} sm={1}>
              <img src={cSharp} alt="" className="cSharpModal_modal_logo_img" />
            </Grid>
            <Grid item sm={12} xs={12}>
              <DialogTitle
                id="dialog-title"
                className="cSharpModal_dialog_title"
              >
                <b>{t("homePage.cSharpTitle")}</b>
              </DialogTitle>
            </Grid>
          </Grid>
          <DialogContent>
            <Grid item sm={12}>
              <DialogContentText
                id="dialog-description"
                className="cSharpModal_dialog_content"
              >
                {t("homePage.cSharpGeneral")}
              </DialogContentText>
            </Grid>
            <Grid item sm={12}>
              <Typography variant="h6" className="cSharpModal_modal_typography">
                {t("homePage.cSharSimilarJavaTitle")}
              </Typography>
              <DialogContentText
                id="dialog-description"
                className="cSharpModal_dialog_content"
              >
                {t("homePage.cSharSimilarJavaText")}
              </DialogContentText>
            </Grid>
            <Grid item sm={12}>
              <Typography variant="h6" className="cSharpModal_modal_typography">
                {t("homePage.cSharWhatPayMoreTitle")}
              </Typography>
              <DialogContentText
                id="dialog-description"
                className="cSharpModal_dialog_content"
              >
                {t("homePage.cSharWhatPayMoreText")}
              </DialogContentText>
            </Grid>
            <Grid item sm={12}>
              <Typography variant="h6" className="cSharpModal_modal_typography">
                {t("homePage.cSharDifficultTitle")}
              </Typography>
              <DialogContentText
                id="dialog-description"
                className="cSharpModal_dialog_content"
              >
                {t("homePage.cSharDifficultText")}
              </DialogContentText>
              <DialogActions className="cSharp_modal_dialogActions">
                <Button
                  variant="outlined"
                  className="cSharp_modal_button"
                  onClick={modalClose}
                >
                  {t("buttons.close")}
                </Button>
              </DialogActions>
            </Grid>
          </DialogContent>
        </Grid>
      </Dialog>
    </div>
  );
}
