import React from "react";
import {
  Grid,
  Typography,
  makeStyles,
  createStyles,
  Theme,
} from "@material-ui/core";
import img3 from "../../../images/midenas_logo_small.png";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    text: {
      marginTop: "20px",
      color: "white",
    },
    whyUs_div: {
      alignContent: "center",
      justifyContent: "center",
    },
    img: {
      width: "100%",
      paddingRight: "20px",
      height: "auto",
      [theme.breakpoints.down("xs")]: {
        paddingRight: 0,
      },
    },
    title: {
      color: "white",
      fontWeight: "bold",
    },
  })
);

export default function AboutSection() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid container spacing={0}>
      <Grid item sm={6} xs={12}>
        <img src={img3} alt="content1" className="content-img" />
      </Grid>
      <Grid container item sm={6} xs={12} alignContent="flex-start">
        <Typography variant="h5" className={classes.title}>
          {t("homePage.aboutUs")}
        </Typography>
        <Grid className="contectSection_grid">
          <Typography style={{ fontWeight: "0.8rem" }} className={classes.text}>
            {t("homePage.aboutUsContetn1")}
          </Typography>
          <Typography style={{ fontWeight: "0.8rem" }} className={classes.text}>
            {t("homePage.aboutUsContetn2")}
          </Typography>
          <Typography style={{ fontWeight: "0.8rem" }} className={classes.text}>
            {t("homePage.aboutUsContetn3")}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
