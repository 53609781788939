/* eslint-disable no-unreachable */
import React, { useState } from "react";
import { Fade } from "react-awesome-reveal";
import {
  Typography,
  Grid,
  Container,
  makeStyles,
  createStyles,
  Theme,
} from "@material-ui/core";
import aboutUsImg from "../../images/whyUsNew.png";
import contactSectionImg from "../../images/aboutNew.png";
import technologyExperienceImg from "../../images/technologyExpirience.png";
import CustomCarousel from "../../components/carousel/carousel";
import GoogleMap from "../../components/googleMap/googleMap";
import { Wrapper } from "@googlemaps/react-wrapper";
import Marker from "../../components/marker/marker";
import JavaScriptModal from "../homepageModals/javaScriptModal/javaScriptModals";
import TypeScriptModal from "../homepageModals/typeScriptModal/typeScriptModal";
import ReactModal from "../homepageModals/reactModal/reactModal";
import CustomSoftwareModal from "../homepageModals/customSoftwareModal/customSoftwareModa";
import WebServicesModal from "../homepageModals/webServicesModal/webServicesModal";
import WebApplicationModal from "../homepageModals/webApplicationModal/webApplicationModal";
import WebDesignModal from "../homepageModals/webDesignModal/webDesignModal";
import AngularModal from "../homepageModals/angularModal/angularModa";
import DesignModal from "../homepageModals/designModal/designModal";
import CSharpModal from "../homepageModals/cSharpModal/cSharpModal";
import DotNetModal from "../homepageModals/dotNetModal/dotNetModal";
import DatabaseModal from "../homepageModals/databaseModal/databaseModal";
import { useTranslation } from "react-i18next";
import TechnologyExperience from "../contentSectionComp/technologyExperience/technologyExperience";
import ContactUsSection from "../contentSectionComp/contactUsSection/contactUsSection";
import WhyUsSection from "../contentSectionComp/whyUsSection/whyUsSection";
import AboutSection from "../contentSectionComp/aboutSection/aboutSection";
import "./contentSection.css";
import Map from "../../components/map/map";
import DesignRushSection from "../designRushSection/designRushSection";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 345,
      borderRadius: "20px",
      marginTop: "20px",
      [theme.breakpoints.up("xs")]: {
        marginLeft: "5px",
        alignContent: "center",
        justifyContent: "center",
      },
      boxShadow: "0 8px 40px -12px rgba(0,0,0,0.5)",
      "&:hover": {
        boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.5)",
      },
    },
    about_container: {
      width: "100%",
      alignContent: "center",
      alignItems: "center",
      padding: "20px",
    },
    aboutUs_div: {
      padding: "120px 0px",
      backgroundImage: `url(${aboutUsImg})`,
      backgroundSize: "cover",
    },
    gallery_title: {
      color: "#849AA3",
      fontWeight: "bold",
    },

    contactUsSection: {
      padding: "120px 0",
      backgroundImage: `url(${contactSectionImg})`,
      backgroundSize: "cover",
    },
    googleMap_grid: {
      alignContent: "center",
      justifyContent: "center",
    },
    whyUsSection: {
      color: "#849AA3",
      padding: "120px 0",
    },
    carouselSection: {
      padding: "120px 0",
      backgroundSize: "cover",
    },
  })
);

function ContentSection() {
  const { t } = useTranslation();
  const classes = useStyles();

  const [typeScriptModal, setTypeScriptModal] = useState(false);
  const [customSoftwareModal, setCustomSoftwareModal] = useState(false);
  const [webServicesModal, setWebServicesModal] = useState(false);
  const [webApplicationModal, setWebApplicationModal] = useState(false);
  const [webDesignModal, setWebDesignModal] = useState(false);
  const [designModal, setDesignModal] = useState(false);
  const [cSharpModal, setCSharpModal] = useState(false);
  const [databaseModal, setDatabaseModal] = useState(false);

  const handleTypeScriptModalClose = () => {
    setTypeScriptModal(false);
  };

  const handleTypeScriptModalOpen = () => {
    setTypeScriptModal(true);
  };

  const handleCustomSoftwareModalClose = () => {
    setCustomSoftwareModal(false);
  };

  const handleCustomSoftwareModalOpen = () => {
    setCustomSoftwareModal(true);
  };

  const handleWebServiceModalClose = () => {
    setWebServicesModal(false);
  };

  const handleWebServiceModalOpen = () => {
    setWebServicesModal(true);
  };

  const handleWebApplicationModalClose = () => {
    setWebApplicationModal(false);
  };

  const handleWebApplicationModalOpen = () => {
    setWebApplicationModal(true);
  };

  const handleWebDeisgnModalClose = () => {
    setWebDesignModal(false);
  };

  const handleWebDeisgnModalOpen = () => {
    setWebDesignModal(true);
  };

  const handleDesignModalClose = () => {
    setDesignModal(false);
  };

  const handleDesignModalOpen = () => {
    setDesignModal(true);
  };

  const handleCSharpModalClose = () => {
    setCSharpModal(false);
  };

  const handleCSharpModalOpen = () => {
    setCSharpModal(true);
  };

  const handleDatabaseModalClose = () => {
    setDatabaseModal(false);
  };

  const handleDatabaseModalOpen = () => {
    setDatabaseModal(true);
  };

  const center = { lat: 45.250375064551505, lng: 19.83732959049245 };
  const zoom = 16;

  function ClearDiv(elementID) {
    document.getElementById(elementID).innerHTML = "";
  }

  return (
    <>
      <div>
        <Fade triggerOnce>
          <TechnologyExperience />
        </Fade>
      </div>

      <div className={classes.contactUsSection}>
        <Fade triggerOnce>
          <ContactUsSection />
        </Fade>
      </div>
      <div>
        <Fade triggerOnce>
          <Grid container spacing={0} className={classes.googleMap_grid}>
            <Grid item sm={12} xs={12}>
              <Map />
            </Grid>
          </Grid>
        </Fade>
      </div>
      <Grid
        item
        md={12}
        style={{
          justifyContent: "center",
          textAlign: "center",
          padding: "20px",
        }}
      >
        <Fade triggerOnce>
          <DesignRushSection />
        </Fade>
      </Grid>
      <Container className={classes.whyUsSection} style={{ padding: "20px" }}>
        <Fade triggerOnce>
          <WhyUsSection />
        </Fade>
      </Container>

      <div
        className={classes.aboutUs_div}
        onClick={ClearDiv}
        id="aboutUsSection"
      >
        <Container className={classes.about_container}>
          <Fade triggerOnce>
            <AboutSection />
          </Fade>
        </Container>
      </div>
      <TypeScriptModal
        open={typeScriptModal}
        modalClose={handleTypeScriptModalClose}
      />
      <CustomSoftwareModal
        open={customSoftwareModal}
        modalClose={handleCustomSoftwareModalClose}
      />
      <WebServicesModal
        open={webServicesModal}
        modalClose={handleWebServiceModalClose}
      />
      <WebApplicationModal
        open={webApplicationModal}
        modalClose={handleWebApplicationModalClose}
      />
      <WebDesignModal
        open={webDesignModal}
        modalClose={handleWebDeisgnModalClose}
      />
      <DesignModal open={designModal} modalClose={handleDesignModalClose} />
      <CSharpModal open={cSharpModal} modalClose={handleCSharpModalClose} />
      <DatabaseModal
        open={databaseModal}
        modalClose={handleDatabaseModalClose}
      />
    </>
  );
}

export default ContentSection;
