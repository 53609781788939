import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import { useLearninigStyles } from "./learningStyles";
import { t } from "i18next";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import NumberOne from "../../images/1.png";
import NumberTwo from "../../images/2.png";
import NumberThree from "../../images/3.png";
import NumberFour from "../../images/4.png";
import { useTheme } from "@material-ui/core";

export default function Steps() {
  const { classes } = useLearninigStyles();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <Grid container justifyContent={"center"} sx={{ padding: "30px" }}>
      <Grid item lg={2.5} md={12} className={classes.stepWrapper}>
        {/* Step 1 */}
        <Grid
          className={classes.step}
          sx={{
            backgroundImage: `url(${NumberOne})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: isTablet ? "10%" : "24%",
            backgroundPosition: "center",
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography className={classes.stepContent} variant="h6">
            {t("outsourcingServices.stepOne")}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        item
        lg={0.5}
        md={1}
        container
        alignItems="center"
        justifyContent="center"
      >
        <ArrowRightIcon
          fontSize="large"
          sx={{ fontSize: "60px", color: "#6A7C82" }}
        />
      </Grid>
      <Grid item lg={2.5} md={12} className={classes.stepWrapper}>
        <Box
          className={classes.step}
          sx={{
            backgroundImage: `url(${NumberTwo})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: isTablet ? "19%" : "43%",
            backgroundPosition: "center",
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography className={classes.stepContent} variant="h6">
            {t("outsourcingServices.stepTwo")}
          </Typography>
        </Box>
      </Grid>
      <Grid
        item
        lg={0.3}
        md={1}
        container
        alignItems="center"
        justifyContent="center"
      >
        <ArrowRightIcon
          fontSize="large"
          sx={{ fontSize: "60px", color: "#6A7C82" }}
        />
      </Grid>
      <Grid item lg={2.5} md={12} className={classes.stepWrapper}>
        <Box
          className={classes.step}
          sx={{
            backgroundImage: `url(${NumberThree})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: isTablet ? "19%" : "43%",
            backgroundPosition: "center",
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography className={classes.stepContent} variant="h6">
            {t("outsourcingServices.stepThree")}
          </Typography>
        </Box>
      </Grid>
      <Grid
        item
        lg={0.3}
        md={1}
        container
        alignItems="center"
        justifyContent="center"
      >
        <ArrowRightIcon
          fontSize="large"
          sx={{ fontSize: "60px", color: "#6A7C82" }}
        />
      </Grid>
      <Grid item lg={2.5} md={12} className={classes.stepWrapper}>
        <Box
          className={classes.step}
          sx={{
            backgroundImage: `url(${NumberFour})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: isTablet ? "22%" : "50%",
            backgroundPosition: "center",
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography className={classes.stepContent} variant="h6">
            {t("outsourcingServices.stepFour")}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
}
