import { makeStyles } from "tss-react/mui";
import { Theme } from "@mui/material/styles";
import { red } from "@material-ui/core/colors";

export const useClientPrioritiesSectionStyles = makeStyles()(
  (theme: Theme) => ({
    title: {
      color: "#fff",
    },
    card: {
      width: "100%",
      height: "100%",
      bordeRadius: "inherit",
      cursor: "pointer",
      backgroundSize: "100% 100%",
      "& .frontContent": {
        "& p": {
          //   fontSize: "25px",
          //   fontWeight: 700,
          //   opacity: 1,
          background:
            "linear-gradient(88deg, #859BA3 -1.69%, #353E41 142.65%), linear-gradient(0deg, rgba(196, 196, 196, 0.28), rgba(196, 196, 196, 0.28))",
          //   backgroundClip: "text",
          WebkitBackgroundClip: "text",
          //   WebkitTextFillColor: "transparent",
          transition: "all 0.6s cubic-bezier(0.23, 1, 0.320, 1)",
        },
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        transition: "all 0.6s cubic-bezier(0.23, 1, 0.320, 1)",
      },
      "& .content": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        gap: "10px",
        background:
          "linear-gradient(88deg, #859BA3 -1.69%, #353E41 142.65%), linear-gradient(0deg, rgba(196, 196, 196, 0.28), rgba(196, 196, 196, 0.28))",
        color: "#e8e8e8",
        padding: "20px",
        lineHeight: "1.5",
        borderRadius: "5px",
        pointerEvents: "none",
        transform: "translateY(96%)",
        transition: "all 0.6s cubic-bezier(0.23, 1, 0.320, 1)",
        fontSize: "15px",
      },
      "&:hover": {
        "& .content": {
          transform: "translateY(0)",
        },
        "& .frontContent": {
          transform: "translateY(-30%)",
        },
        "& .frontContent p": {
          opacity: 0,
        },
      },
    },

    cardContainer: {
      width: "350px",
      height: "350px",
      position: "relative",
      borderRadius: "10px",
      boxShadow: "0 10px 30px rgba(0, 0, 0, 0.2)",
      overflow: "hidden",
    },
    heading: {
      //   fontSize: "32px",
      //   fontWeight: 700,
    },
    box: {
      background: "#6A7C82",
      padding: "40px",
      borderRadius: "15px",
    },
    icon: {
      width: "580px",
      marginLeft: "150px",
    },
    iconsSubtitle: {
      color: "#fff",
      fontSize: "18px",
      marginTop: "auto",
    },
    textTittle: {
      color: "#fff",
      fontSize: "38px",
      marginTop: "auto",
    },
    textSubtitle: {
      color: "#fff",
      fontSize: "16.5px",
      marginTop: "auto",
    },
    iconWrapper: {
      textAlign: "center",
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-start",
    },
    gridContainer: {
      height: "100%",
    },
    root: {
      borderRadius: "20px",
      marginTop: "40px",
      [theme.breakpoints.up("xs")]: {
        alignContent: "center",
        justifyContent: "center",
        alignItems: "center",
        alignSelf: "center",
      },
      boxShadow: "0 8px 40px -12px rgba(0,0,0,0.5)",
      "&:hover": {
        boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.5)",
      },
    },
    media: {
      height: 0,
      paddingTop: "56.25%",
      width: "100%",
      overflow: "hidden",
      backgroundColor: "#cccccc",
    },
    expand: {
      border: "2px solid #849AA3",
      borderRadius: "20px",
      width: "100%",
      background: "transparent",
      color: "#849AA3",
      transform: "rotate(0deg)",
      transition: "all 0.8s",
      marginLeft: "auto",
      "&:hover": {
        background: "#849AA3",
        color: "white",
      },
      [theme.breakpoints.up("xs")]: {
        color: "#849AA3",
        background: "transparent",
      },
    },
    expandOpen: {
      background: "transparent",
      color: "white",
      transform: "rotate(360deg)",
      transition: "all 0.8s",
      [theme.breakpoints.up("xs")]: {
        color: "#849AA3",
        background: "transparent",
      },
    },
    avatar: {
      backgroundColor: red[500],
    },
  })
);
