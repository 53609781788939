import React from "react";
import { useTranslation } from "react-i18next";
import Video from "../../video/video3.mp4";
import { useHistory } from "react-router-dom";
import { contactPage as contactPageRoute } from "../../app/routes";
import { HashLink as Link } from "react-router-hash-link";
import { Grid } from "@material-ui/core";
import "./slider.css";

function Slider() {
  const { t } = useTranslation();
  const history = useHistory();
  const handleContactPage = () => {
    history.push(contactPageRoute());
  };

  return (
    <div className="slider_header" id="home">
      <video autoPlay loop className="slider_back-video" muted playsInline>
        <source src={Video} type="video/mp4" />
      </video>
      <div className="slider_content">
        <div data-aos="fade-left">
          <h6 className="slider-description">{t("slider.description")}</h6>
          <h4 className="slider-caption">{t("slider.caption")}</h4>
          <Grid item xs={12} sm={12}>
            <button onClick={handleContactPage} className="slider_btn">
              {t("buttons.contactUs")}
            </button>
          </Grid>
          <Grid item xs={12} sm={12}>
            <button className="slider_btn">
              <Link
                to="#aboutUsSection"
                style={{ textDecoration: "none", color: "white" }}
              >
                {t("homePage.aboutUs")}
              </Link>
            </button>
          </Grid>
        </div>
      </div>
    </div>
  );
}

export default Slider;
