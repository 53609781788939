import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Button } from "react-bootstrap";
import logo from "../../logo.svg";
import { Container, Grid, Typography } from "@material-ui/core";
import PhoneIcon from "@material-ui/icons/Phone";
import PlaceIcon from "@material-ui/icons/Place";
import MailIcon from "@material-ui/icons/Mail";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import { useTranslation } from "react-i18next";
import "./footer.css";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: "0 0 10px 0",
      width: "100%",
      [theme.breakpoints.up("xs")]: {
        width: "100%",
        display: "flex",
      },
    },
    iconGrid: {
      color: "white",
      fontSize: "2px",
      [theme.breakpoints.up("xs")]: {
        display: "flex",
      },
    },
    iconStyle: {
      fontSize: "20px",
    },
    text: {
      color: "white",
      padding: "5px",
    },
  })
);

function Footer() {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className="footer-container">
      <Grid container className="footer-subscription">
        <Typography variant="h4" className="footer-subscription-heading">
          {t("footer.subscriptionHeading")}
        </Typography>
        <Grid className="footer_inputArea_grid">
          <form>
            <input
              className="footer-input"
              name="email"
              type="email"
              placeholder={t("footer.inputPlaceholder")}
            />
            <Button className="footer_button" variant="outlined">
              {t("buttons.contactUs")}
            </Button>
          </form>
        </Grid>
      </Grid>
      <Container maxWidth="xl" className="footer-links">
        <Grid container className="footer-link-wrapper">
          <Grid item md={4} sm={12} className="footer-link-items">
            <Typography variant="h5" style={{ color: "white" }}>
              {t("footer.aboutUs")}
            </Typography>
            <Typography
              className={classes.text}
              variant="body1"
              style={{ fontSize: "0.8rem" }}
            >
              {t("homePage.aboutUsContetn1")}
            </Typography>
          </Grid>
          <Grid
            container
            item
            md={5}
            sm={12}
            justifyContent="center"
            className="footer-link-items"
          >
            <Typography variant="h5" style={{ color: "white" }}>
              {t("footer.contactUs")}
            </Typography>
            <Grid
              container
              direction="row"
              alignItems="center"
              className={classes.root}
            >
              <Grid className={classes.iconGrid}>
                <PhoneIcon />
              </Grid>
              <Grid className={classes.text}>
                <Typography
                  style={{ fontSize: "0.8rem" }}
                  component={"a"}
                  href="tel:+381213006815"
                >
                  +381 21 300 6815
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              alignItems="center"
              className={classes.root}
            >
              <Grid className={classes.iconGrid}>
                <PhoneAndroidIcon />
              </Grid>
              <Grid className={classes.text}>
                <Typography
                  style={{ fontSize: "0.8rem" }}
                  component={"a"}
                  href="tel:+381668041771"
                >
                  +381 66 8041 771
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              alignItems="center"
              className={classes.root}
            >
              <Grid className={classes.iconGrid}>
                <PlaceIcon />
              </Grid>
              <Grid className={classes.text}>
                <Typography
                  component={"a"}
                  target="_blank"
                  style={{ fontSize: "0.8rem" }}
                  href="https://www.google.com/maps/place/Midenas+d.o.o./@45.2503054,19.8351689,17z/data=!3m1!4b1!4m6!3m5!1s0x475b0d32863fccdf:0x8cb200ca18497379!8m2!3d45.2503054!4d19.8373576!16s%2Fg%2F11mv4xx899?entry=ttu"
                >
                  {t("homePage.contactAddressContent")}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              alignItems="center"
              className={classes.root}
            >
              <Grid className={classes.iconGrid}>
                <MailIcon />
              </Grid>
              <Grid className={classes.text}>
                <Typography
                  component={"a"}
                  style={{ fontSize: "0.8rem" }}
                  href="mailto:info@midenas.rs"
                >
                  {" "}
                  {t("homePage.contanctEmailContent")}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item md={3} sm={12} className="footer-link-items">
            <Typography variant="h5" style={{ color: "white" }}>
              {t("homePage.explore")}
            </Typography>
            <Grid
              container
              direction="row"
              alignItems="center"
              className={classes.root}
            >
              <Grid className={classes.text}>
                <Typography style={{ fontSize: "0.8rem" }}>
                  - {t("header.services")}
                </Typography>
              </Grid>
            </Grid>

            <Grid
              container
              direction="row"
              alignItems="center"
              className={classes.root}
            >
              <Grid className={classes.text}>
                <Typography style={{ fontSize: "0.8rem" }}>
                  - {t("homePage.whyUs")}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Container className="social-media">
        <Grid container spacing={0} className="social-media-wrap">
          <Grid item md={5} xs={12} sm={12} className="footer-logo">
            <img src={logo} className="navbar-logo" alt="logo" width="200px" />
          </Grid>
          <Grid
            item
            md={5}
            xs={12}
            sm={12}
            className="footer_website-rights_grid"
          >
            <small className="website-rights">
              {t("footer.websiteRights")}
            </small>
          </Grid>
          <Grid item md={2} xs={12} sm={12} className="social-icons">
            <a
              className="facebook_icon"
              href="https://www.facebook.com/midenasit/"
              aria-label="Facebook"
              target="_blank"
            >
              <i className="fab fa-facebook-f" />
            </a>
            <a
              className="insta_icon"
              href="https://l.facebook.com/l.php?u=https%3A%2F%2Finstagram.com%2Fit_midenas%3Figshid%3DYmMyMTA2M2Y%253D%26fbclid%3DIwAR3upvNNHiVo2Kl4kc-eet6e1PWrwy45_wDuX8K0B-RIcNvKuUEUxKNzTXw&h=AT2J_rAGr7qhieRLFaTv08GTW2LJXZBxU2OuKhlUCSF5Ecwlq1gziW7gN3qgrTs8C9oGQzaP6iv6eHBzq65On051EdZtzIgfBCiepMWd9a-R0bYbmPmb2sgAstVg-rz-WLiYEA"
              aria-label="Instagram"
              target="_blank"
            >
              <i className="fab fa-instagram" />
            </a>
            <a
              className="linkedIn_icon"
              href="https://www.linkedin.com/company/midenas-doo/about/"
              aria-label="LinkedIn"
              target="_blank"
            >
              <i className="fab fa-linkedin" />
            </a>
            <a
              className="whatsapp_icon"
              href="https://wa.me/+381668041771"
              target="_blank"
              aria-label="WhatsApp"
            >
              <i className="fab fa-whatsapp" />
            </a>
            <a
              className="viber_icon"
              href="viber://chat?number=+381668041771"
              aria-label="Viber"
              target="_blank"
            >
              <i className="fab fa-viber" />
            </a>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default Footer;
