/* eslint-disable jsx-a11y/iframe-has-title */
import { Box, Grid, Typography } from "@mui/material";
import { useMapStyles } from "./mapStyles";
import BackgroundAbout from "../../images/bacground_abiut.png";
import { useTranslation } from "react-i18next";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CallIcon from "@mui/icons-material/Call";
import { LocationOn } from "@mui/icons-material";

export default function Map() {
  const { t } = useTranslation();
  const { classes } = useMapStyles();

  return (
    <Box position="relative" marginBottom={"100px"}>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2808.875556767559!2d19.834782676649745!3d45.25030914768398!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475b0d32863fccdf%3A0x8cb200ca18497379!2sMidenas%20d.o.o.!5e0!3m2!1sen!2srs!4v1688125558507!5m2!1sen!2srs"
        width="100%"
        height="450"
        loading="lazy"
        style={{
          borderRadius: "15px",
          border: "none",
          background: "#000000",
        }}
      ></iframe>
      {/* <Box
        className={classes.box}
        style={{
          backgroundImage: `url(${BackgroundAbout})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          position: "absolute",
          top: 350,
          left: 355,
          width: "60%",
          height: "auto",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid item md={12} mb={2}>
          <Typography variant="h6" className={classes.textTittle}>
            {t("mapSection.mapTitle")}
          </Typography>
        </Grid>
        <Grid
          container
          direction="column"
          alignItems="center"
          className={classes.gridContainer}
        >
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            className={classes.gridContainer}
          >
            <Grid item md={4}>
              <Typography
                variant="body1"
                className={classes.textSubtitle}
                component={"a"}
                href="https://www.google.com/maps?output=search&q=midenas+novi+sad&entry=mc&sa=X&ved=2ahUKEwjUoMmi6vT_AhVsg_0HHUyPBeUQ0pQJegQICBAB"
                target="_blank"
              >
                <LocationOnIcon sx={{ marginRight: "5px", marginTop: "1%" }} />
                Serbia, Novi Sad 21000
              </Typography>
              <Typography
                component={"a"}
                href="https://www.google.com/maps?output=search&q=midenas+novi+sad&entry=mc&sa=X&ved=2ahUKEwjUoMmi6vT_AhVsg_0HHUyPBeUQ0pQJegQICBAB"
                target="_blank"
                variant="body1"
                className={classes.textSubtitle}
                sx={{ marginLeft: "10.5%" }}
              >
                Bulevar Oslobodjanja 78
              </Typography>
            </Grid>

            <Grid item md={4}>
              <Typography
                variant="body1"
                className={classes.textSubtitle}
                component={"a"}
                href="tel:+381213031043"
              >
                <CallIcon sx={{ marginTop: "1%" }} />
                +381 21 303 1043
              </Typography>
              <Typography
                variant="body1"
                sx={{ marginLeft: "5.5%" }}
                className={classes.textSubtitle}
                component={"a"}
                href="tel:+381668041771"
              >
                +381 66 8041 771
              </Typography>
            </Grid>

            <Grid item md={4}>
              <Typography
                variant="body1"
                sx={{ marginBottom: "6%" }}
                className={classes.textSubtitle}
                component={"a"}
                href="mailto:info@midenas.rs"
              >
                <EmailIcon sx={{ marginRight: "2%", marginTop: "0.5%" }} />
                info@midenas.rs
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Box> */}
    </Box>
  );
}
