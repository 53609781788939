import React from "react";
import Header from "../navigations/header/header";
import LearningPage from "./learningPage";
import Footer from "../footer/footer";

export default function LearningMainPage() {
  return (
    <>
      <Header />
      <LearningPage />
      <Footer />
    </>
  );
}
