import React from "react";
import {
  Grid,
  Typography,
  makeStyles,
  createStyles,
  Theme,
} from "@material-ui/core";
import designrush from "../../images/designrush.png";
import { useTranslation } from "react-i18next";
import { useMediaQuery, useTheme } from "@mui/material";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      justifyContent: "center",
      textAlign: "center",
    },
    text: {
      marginTop: "20px",
    },
    whyUs_div: {
      alignContent: "center",
      justifyContent: "center",
      fontWeight: "bold",
      color: "#849AA3",
      textDecoration: "none",
    },
    img: {
      width: "75%",
      paddingRight: "20px",
      textAlign: "center",
      height: "auto",
      cursor: "pointer",
      [theme.breakpoints.down("xs")]: {
        paddingRight: 0,
      },
    },
    imgContainer: {
      display: "flex",
      justifyContent: "right",
    },
    removeHover: {
      "&:hover": {
        color: "#6a7b82",
        textDecoration: "none",
      },
    },
  })
);

export default function DesignRushSection() {
  const theme = useTheme();
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid className={classes.root} container spacing={0}>
      <Grid
        container
        item
        sm={6}
        xs={12}
        alignContent="center"
        justifyContent="center"
      >
        <Typography
          variant="h5"
          component={"a"}
          className={`${classes.whyUs_div} ${classes.removeHover}`}
          href="https://www.designrush.com/agency/profile/midenas"
          target="_blank"
        >
          {t("homePage.designRushTitle")}
        </Typography>
      </Grid>
    </Grid>
  );
}
