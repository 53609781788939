import { makeStyles } from "tss-react/mui";
import { Theme } from "@mui/material/styles";

export const useLearninigStyles = makeStyles()((theme: Theme) => ({
  title: {
    color: "#fff",
  },
  box: {
    background: "#6A7C82",
    // padding: "40px",
    borderRadius: "15px",
  },
  icon: {
    width: "500px",
    marginLeft: "200px",
  },
  iconsSubtitle: {
    color: "#fff",
    fontSize: "18px",
    marginTop: "auto",
  },
  text: {
    color: "#6A7C82",
    fontSize: "38px",
  },
  textTittle: {
    color: "#6A7C82",
    fontSize: "38px",
    marginTop: "auto",
  },
  textSubtitle: {
    color: "#6A7C82",
    fontSize: "16.5px",
    marginTop: "auto",
  },
  iconWrapper: {
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  gridContainer: {
    height: "100%",
  },
  stepWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  step: {
    width: "60%",
    [theme.breakpoints.down("md")]: {
      width: "50%",
    },
    height: "180px",
    borderRadius: "15px",
    textAlign: "center",
    backgroundColor: "#6A7C82",
  },
  stepContent: {
    color: "white",
    fontSize: "24.5px",
  },
}));
