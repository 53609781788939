import { Box, Grid, Typography } from "@mui/material";
import { useLearninigStyles } from "./learningStyles";
import { useTranslation } from "react-i18next";
import NumberOne from "../../images/1.png";
import NumberTwo from "../../images/2.png";
import NumberThree from "../../images/3.png";
import NumberFour from "../../images/4.png";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import React from "react";

export default function StepsMobile() {
  const { t } = useTranslation();
  const { classes } = useLearninigStyles();
  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <Grid container>
        <Grid item xs={12} md={12} className={classes.stepWrapper}>
          {/* Step 1 */}
          <Box
            className={classes.step}
            sx={{
              backgroundImage: `url(${NumberOne})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "15%",
              backgroundPosition: "center",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              width: "100% !important",
            }}
          >
            <Typography className={classes.stepContent} variant="h6">
              {t("outsourcingServices.stepOne")}
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          my={2}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <ArrowDropDownIcon
            fontSize="large"
            sx={{ fontSize: "60px", color: "#6A7C82" }}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          {/* Step 2 */}
          <Box
            className={classes.step}
            sx={{
              backgroundImage: `url(${NumberTwo})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "24%",
              backgroundPosition: "center",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              width: "100% !important",
            }}
          >
            <Typography className={classes.stepContent} variant="h6">
              {t("outsourcingServices.stepTwo")
                .split("\n")
                .map((text, index) => (
                  <React.Fragment key={index}>
                    {text}
                    <br />
                  </React.Fragment>
                ))}
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          my={2}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <ArrowDropDownIcon
            fontSize="large"
            sx={{ fontSize: "60px", color: "#6A7C82" }}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          {/* Step 3 */}
          <Box
            className={classes.step}
            sx={{
              backgroundImage: `url(${NumberThree})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "24%",
              backgroundPosition: "center",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              width: "100%!important",
            }}
          >
            <Typography className={classes.stepContent} variant="h6">
              {t("outsourcingServices.stepThree")
                .split("\n")
                .map((text, index) => (
                  <React.Fragment key={index}>
                    {text}
                    <br />
                  </React.Fragment>
                ))}
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          my={2}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <ArrowDropDownIcon
            fontSize="large"
            sx={{ fontSize: "60px", color: "#6A7C82" }}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          {/* Step 4 */}
          <Box
            className={classes.step}
            sx={{
              backgroundImage: `url(${NumberFour})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "24%",
              backgroundPosition: "center",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              width: "100%!important",
            }}
          >
            <Typography className={classes.stepContent} variant="h6">
              {t("outsourcingServices.stepFour")}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
