import {
  Box,
  Fade,
  Grid,
  Grow,
  Typography,
  Zoom,
  useMediaQuery,
  useTheme,
} from "@mui/material";
// import cSharp from "../../../images/csharp.png";
import cSharp from "../../../images/cSharp.png";
import cSharpColor from "../../../images/csharpblue_1.png";
import dotNetColor from "../../../images/dotnet_2.png";
import dotNet from "../../../images/dotnet_color.png";
import React from "../../../images/react_2.png";
import ReactColor from "../../../images/react_color_1.png";
import nodeJs from "../../../images/node.js_2.png";
import nodeJsColor from "../../../images/node.js_color_1.png";
import angular from "../../../images/angular_2.png";
import angularColor from "../../../images/angular_color_1.png";
import java from "../../../images/java_2.png";
import javaColor from "../../../images/java_color_1.png";
import { useState } from "react";

import { useTranslation } from "react-i18next";
import { useTechnologyExperienceStyles } from "./technologyExperienceStyles";
import CSharpModal from "../../homepageModals/cSharpModal/cSharpModal";
import DotNetModal from "../../homepageModals/dotNetModal/dotNetModal";
import ReactModal from "../../homepageModals/reactModal/reactModal";
import AngularModal from "../../homepageModals/angularModal/angularModa";
import NodeJsModal from "../../homepageModals/nodeJsModal/nodeJsModal";
import JavaModal from "../../homepageModals/javaModal/javaModal";

export default function TechnologyExperience() {
  const theme = useTheme();
  const { t } = useTranslation();
  const { classes } = useTechnologyExperienceStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [isVisibleIconCSharp, setIsVisibleIconCSharp] =
    useState<boolean>(false);

  const [isVisibleIcondotNet, setIsVisibleIcondotNet] =
    useState<boolean>(false);

  const [isIsVisibleIconReact, SetIsVisibleIconReact] =
    useState<boolean>(false);

  const [isIsVisibleIconNodeJs, SetIsVisibleIconNodeJs] =
    useState<boolean>(false);

  const [isIsVisibleIconAngular, SetIsVisibleIconAngular] =
    useState<boolean>(false);

  const [isIsVisibleIconJava, SetIsVisibleIconJava] = useState<boolean>(false);
  const [cSharpModal, setCSharpModal] = useState(false);

  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [dotNetModal, setDotNetModal] = useState(false);
  const [reactModal, setReactModal] = useState(false);
  const [javaScriptModal, setJavaScriptModal] = useState(false);
  const [angularModal, setAngularModal] = useState(false);
  const [nodeJsModal, setNodeJsModal] = useState(false);
  const [javaModal, setJavaModal] = useState(false);

  console.log(isOpenModal);

  return (
    <Box className={classes.box}>
      <Grid item md={12} mb={3}>
        <Typography
          variant="h6"
          className={classes.title}
          sx={{ textAlign: isMobile ? "center" : "" }}
        >
          {t("homePage.technologyExpirience")}
        </Typography>
      </Grid>
      <Grid container>
        <Grid md={2} xs={6} className={classes.iconWrapper}>
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="flex-end"
            className={classes.gridContainer}
          >
            {isVisibleIconCSharp ? (
              <img
                src={cSharpColor}
                className={classes.icon}
                alt="C#"
                onMouseEnter={() => setIsVisibleIconCSharp(true)}
                onMouseLeave={() => setIsVisibleIconCSharp(false)}
                onClick={() => setCSharpModal(true)}
                style={{
                  cursor: "pointer",
                  transform: isVisibleIconCSharp ? "scale(1.1)" : "scale(1)",
                  transition: "transform 0.5s ease",
                }}
              />
            ) : (
              <img
                src={cSharp}
                className={classes.icon}
                alt="C#"
                onMouseEnter={() => setIsVisibleIconCSharp(true)}
                onMouseLeave={() => setIsVisibleIconCSharp(false)}
                onClick={() => setCSharpModal(true)}
                style={{
                  cursor: "pointer",
                  transform: isVisibleIconCSharp ? "scale(1.1)" : "scale(1)",
                  transition: "transform 0.5s ease",
                }}
              />
            )}
            <Typography className={classes.iconsSubtitle}>
              {t("homePage.cSharp")}
            </Typography>
          </Grid>
        </Grid>

        <Grid md={2} xs={6} className={classes.iconWrapper}>
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="flex-end"
            className={classes.gridContainer}
          >
            {isVisibleIcondotNet ? (
              <img
                src={dotNet}
                className={classes.icon}
                alt=".Net"
                onMouseEnter={() => setIsVisibleIcondotNet(true)}
                onMouseLeave={() => setIsVisibleIcondotNet(false)}
                onClick={() => setDotNetModal(true)}
                style={{
                  cursor: "pointer",
                  transform: isVisibleIcondotNet ? "scale(1.1)" : "scale(1)",
                  transition: "transform 0.5s ease",
                }}
              />
            ) : (
              <img
                src={dotNetColor}
                className={classes.icon}
                alt=".Net"
                onMouseEnter={() => setIsVisibleIcondotNet(true)}
                onMouseLeave={() => setIsVisibleIcondotNet(false)}
                onClick={() => setDotNetModal(true)}
                style={{
                  cursor: "pointer",
                  transform: isVisibleIcondotNet ? "scale(1.1)" : "scale(1)",
                  transition: "transform 0.5s ease",
                }}
              />
            )}
            <Typography className={classes.iconsSubtitle}>
              {t("homePage.dotNet")}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          md={2}
          xs={6}
          className={classes.iconWrapper}
          my={isMobile ? 2 : 0}
        >
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="flex-end"
            className={classes.gridContainer}
          >
            {isIsVisibleIconReact ? (
              <img
                src={ReactColor}
                className={classes.icon}
                alt="React"
                onMouseEnter={() => SetIsVisibleIconReact(true)}
                onMouseLeave={() => SetIsVisibleIconReact(false)}
                onClick={() => setReactModal(true)}
                style={{
                  cursor: "pointer",
                  transform: isIsVisibleIconReact ? "scale(1.1)" : "scale(1)",
                  transition: "transform 0.5s ease",
                }}
              />
            ) : (
              <img
                src={React}
                className={classes.icon}
                alt="React"
                onMouseEnter={() => SetIsVisibleIconReact(true)}
                onMouseLeave={() => SetIsVisibleIconReact(false)}
                onClick={() => setReactModal(true)}
                style={{
                  cursor: "pointer",
                  transform: isIsVisibleIconReact ? "scale(1.1)" : "scale(1)",
                  transition: "transform 0.5s ease",
                }}
              />
            )}
            <Typography className={classes.iconsSubtitle}>
              {t("homePage.react")}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          md={2}
          xs={6}
          className={classes.iconWrapper}
          my={isMobile ? 2 : 0}
        >
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="flex-end"
            className={classes.gridContainer}
          >
            {isIsVisibleIconNodeJs ? (
              <img
                src={nodeJsColor}
                className={classes.icon}
                alt="Node.js"
                onMouseEnter={() => SetIsVisibleIconNodeJs(true)}
                onMouseLeave={() => SetIsVisibleIconNodeJs(false)}
                onClick={() => setNodeJsModal(true)}
                style={{
                  cursor: "pointer",
                  transform: isIsVisibleIconNodeJs ? "scale(1.1)" : "scale(1)",
                  transition: "transform 0.5s ease",
                }}
              />
            ) : (
              <img
                src={nodeJs}
                className={classes.icon}
                alt="C#"
                onMouseEnter={() => SetIsVisibleIconNodeJs(true)}
                onMouseLeave={() => SetIsVisibleIconNodeJs(false)}
                onClick={() => setNodeJsModal(true)}
                style={{
                  cursor: "pointer",
                  transform: isIsVisibleIconNodeJs ? "scale(1.1)" : "scale(1)",
                  transition: "transform 0.5s ease",
                }}
              />
            )}

            <Typography className={classes.iconsSubtitle}>
              {t("homePage.nodeJs")}
            </Typography>
          </Grid>
        </Grid>
        <Grid md={2} xs={6} className={classes.iconWrapper}>
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="flex-end"
            className={classes.gridContainer}
          >
            {isIsVisibleIconAngular ? (
              <img
                src={angularColor}
                className={classes.icon}
                alt="Angular"
                onMouseEnter={() => SetIsVisibleIconAngular(true)}
                onMouseLeave={() => SetIsVisibleIconAngular(false)}
                onClick={() => setAngularModal(true)}
                style={{
                  cursor: "pointer",
                  transform: isIsVisibleIconAngular ? "scale(1.1)" : "scale(1)",
                  transition: "transform 0.5s ease",
                }}
              />
            ) : (
              <img
                src={angular}
                className={classes.icon}
                alt="Angular"
                onMouseEnter={() => SetIsVisibleIconAngular(true)}
                onMouseLeave={() => SetIsVisibleIconAngular(false)}
                onClick={() => setAngularModal(true)}
                style={{
                  cursor: "pointer",
                  transform: isIsVisibleIconAngular ? "scale(1.1)" : "scale(1)",
                  transition: "transform 0.5s ease",
                }}
              />
            )}

            <Typography className={classes.iconsSubtitle}>
              {t("homePage.angular")}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          md={2}
          xs={6}
          className={classes.iconWrapper}
          mt={isMobile ? 1 : 0}
        >
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="flex-end"
            className={classes.gridContainer}
          >
            {isIsVisibleIconJava ? (
              <img
                src={javaColor}
                className={classes.icon}
                alt="Java"
                onMouseEnter={() => SetIsVisibleIconJava(true)}
                onMouseLeave={() => SetIsVisibleIconJava(false)}
                onClick={() => setJavaModal(true)}
                style={{
                  cursor: "pointer",
                  transform: isIsVisibleIconJava ? "scale(1.1)" : "scale(1)",
                  transition: "transform 0.5s ease",
                }}
              />
            ) : (
              <img
                src={java}
                className={classes.icon}
                alt="Java"
                onMouseEnter={() => SetIsVisibleIconJava(true)}
                onMouseLeave={() => SetIsVisibleIconJava(false)}
                onClick={() => setJavaModal(true)}
                style={{
                  cursor: "pointer",
                  transform: isIsVisibleIconJava ? "scale(1.1)" : "scale(1)",
                  transition: "transform 0.5s ease",
                }}
              />
            )}

            <Typography className={classes.iconsSubtitle}>
              {t("homePage.java")}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {/* <CSharpModal open={isOpenModal} handleClose={handleCloseModal} />
      <DotNetModal
        open={isOpenModalDotNet}
        handleClose={handleCloseDotNetModal}
      />
      <ReactModal open={isOpenModalReact} handleClose={handleCloseReactModal} />
      <NodeJsModal
        open={isOpenModalNodeJs}
        handleClose={handleCloseNodeJsModal}
      />
      <AngularModal
        open={isOpenModalAngular}
        handleClose={handleCloseAngularModal}
      />

      <JavaModal open={isOpenModalJava} handleClose={handleCloseJavaModal} /> */}
      <CSharpModal
        open={cSharpModal}
        modalClose={() => setCSharpModal(false)}
      />
      <DotNetModal
        open={dotNetModal}
        modalClose={() => setDotNetModal(false)}
      />
      <ReactModal open={reactModal} modalClose={() => setReactModal(false)} />
      <AngularModal
        open={angularModal}
        modalClose={() => setAngularModal(false)}
      />
      <NodeJsModal
        open={nodeJsModal}
        modalClose={() => setNodeJsModal(false)}
      />
      <JavaModal open={javaModal} modalClose={() => setJavaModal(false)} />
    </Box>
  );
}
