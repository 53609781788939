import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLearninigStyles } from "./learningStyles";
import React from "react";
import Steps from "./steps";
import StepsMobile from "./stepsMobile";

export default function LearningPage() {
  const theme = useTheme();
  const { classes } = useLearninigStyles();
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Grid
      container
      sx={{
        padding: "40px",
        width: "100%",
      }}
    >
      <Grid item xs={12} sx={{ marginLeft: "10%" }}>
        <Typography className={classes.text} variant="h6">
          {t("outsourcingServices.outsourcingTitle")}
        </Typography>
        <Typography
          className={classes.textSubtitle}
          sx={{ textAlign: isMobile ? "left" : "left", mt: 2 }}
        >
          <p>
            {t("outsourcingServices.outsourcingDesc1")
              .split("\n")
              .map((text, index) => (
                <React.Fragment key={index}>
                  {text}
                  <br />
                </React.Fragment>
              ))}
          </p>
          <p>
            {t("outsourcingServices.outsourcingDesc2")
              .split("\n")
              .map((text, index) => (
                <React.Fragment key={index}>
                  {text}
                  <br />
                </React.Fragment>
              ))}
          </p>
        </Typography>
        <Typography
          className={classes.textSubtitle}
          sx={{ textAlign: isMobile ? "left" : "left", mt: 2 }}
        >
          <p>
            {t("outsourcingServices.outsourcingDesc3")
              .split("\n")
              .map((text, index) => (
                <React.Fragment key={index}>
                  {text}
                  <br />
                </React.Fragment>
              ))}
          </p>
        </Typography>
        <Typography
          className={classes.textTittle}
          variant="h6"
          sx={{ textAlign: isMobile ? "left" : "left", mt: 4 }}
        >
          {t("outsourcingServices.howOutsourcingTitle")}
        </Typography>
        <Typography
          className={classes.textSubtitle}
          sx={{ textAlign: isMobile ? "left" : "left", mt: 2 }}
        >
          <p>
            {t("outsourcingServices.howOutsourcingDesc1")
              .split("\n")
              .map((text, index) => (
                <React.Fragment key={index}>
                  {text}
                  <br />
                </React.Fragment>
              ))}
          </p>
        </Typography>
        <Typography
          className={classes.textSubtitle}
          sx={{ textAlign: isMobile ? "left" : "left", mt: 2 }}
        >
          <p>
            {t("outsourcingServices.howOutsourcingDesc2")
              .split("\n")
              .map((text, index) => (
                <React.Fragment key={index}>
                  {text}
                  <br />
                </React.Fragment>
              ))}
          </p>
        </Typography>
        <Typography
          className={classes.textTittle}
          variant="h6"
          align="center"
          sx={{ mt: 4 }}
        >
          {t("outsourcingServices.fourStepTitle")}
        </Typography>
      </Grid>
      {isMobile ? <StepsMobile /> : <Steps />}
    </Grid>
  );
}
